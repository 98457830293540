import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../components/Layout'
import { Link } from "gatsby"

import classnames from 'classnames'
import { makeStyles } from "@material-ui/styles";
import { styled } from "@mui/material/styles";
import {browserTitle, seoDescription, seoTitle, keywords, textData} from "../data/story";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Box4Title, SubTitleEnBox, SubTitleWhiteBox} from '../components/Common/Base'
import Stack from "@mui/material/Stack/Stack";
import ProjectHeader from "../components/Common/ProjectHeader";


const useStyles = makeStyles({
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mb25: {
      marginBottom: 25,
    },
    box5Content: {
      backgroundSize: 'contain',
      backgroundPosition: 'left center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${require('../images/about/07.png').default})`,
      height: '40vw',
      maxHeight: 550,
    },
    honor1: {
      color: 'rgba(255,255,255,0.6)',
    }
  });

const Box4 = styled(Container)(({theme}) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1BAFBF',
    backgroundSize: 'auto 80%',
    backgroundPosition: '15% 90%',
    backgroundRepeat: 'no-repeat',
    // backgroundImage: `url(${require('../images/about/05.png').default})`,
    height: 300,
    [theme.breakpoints.up('sm')]: {
      backgroundPosition: '10% 50px',
      height: 360,
    },
    [theme.breakpoints.up('md')]: {
      backgroundPosition: '10% 50px',
      backgroundSize: 'auto 80%',
      height: 500,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundPosition: '-30% 60%',
      backgroundSize: 'auto 80%',
      height: 600,
    }
  }
});

const About = (props) => {
  const { location } = props;
  const classes = useStyles();

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <ProjectHeader
        backImg={require('../images/about/01.jpg').default}
        backVideo={'story/story.m3u8'}
        gridShow
        topContent={(
          <Stack
            alignItems="center"
          >
            <Box
              component="img"
              alt=""
              src={require('../images/about/logoBxg.png').default}
              sx={{
                width: {
                  xs: '40px',
                  sm: '8vw',
                  xl: '120px'
                },
                mb: {
                  xs: '10px',
                  sm: '2vw',
                  xl: '30px'
                },
              }}
            />
            <Stack
              alignItems="flex-start"
              justifyContent="space-between"
              direction="row-reverse"
              sx={{
                width: {
                  xs: '100%',
                  sm: '95%',
                  xl: '80%',
                }
              }}
            >
              <Box
                component="img"
                alt=""
                src={require('../images/about/wyw.png').default}
                width='100%'
              />
              {/*{*/}
                {/*textData.map((item, index) => {*/}
                  {/*return (*/}
                    {/*<Stack*/}
                      {/*key={'text-' + index}*/}
                      {/*justifyContent="space-between"*/}
                      {/*alignItems="flex-start"*/}
                      {/*direction="row-reverse"*/}
                      {/*spacing={0}*/}
                      {/*sx={{*/}
                      {/*}}*/}
                    {/*>*/}
                      {/*{*/}
                        {/*item.map((_item, _index) => {*/}
                          {/*return (*/}
                            {/*<Typography*/}
                              {/*key={'text-' + index + '-' + _index}*/}
                              {/*variant="h5"*/}
                              {/*gutterBottom*/}
                              {/*sx={{*/}
                                {/*color: '#ffffff',*/}
                                {/*fontSize: {*/}
                                  {/*xs: '12px',*/}
                                  {/*sm: '1.6vw',*/}
                                  {/*xl: '20px',*/}
                                {/*},*/}
                                {/*width: {*/}
                                  {/*xs: '13px',*/}
                                  {/*sm: '3vw',*/}
                                  {/*lg: '30px',*/}
                                {/*},*/}
                                {/*lineHeight: 1.2*/}
                              {/*}}*/}
                            {/*>*/}
                              {/*{*/}
                                {/*_item*/}
                              {/*}*/}
                            {/*</Typography>*/}
                          {/*)*/}
                        {/*})*/}
                      {/*}*/}
                    {/*</Stack>*/}
                  {/*)*/}
                {/*})*/}
              {/*}*/}
            </Stack>
          </Stack>
        )}
      />
      {/*box1*/}
      <Container
        ref={eleShow}
        maxWidth="lg"
        sx={{
          pt: {
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
          },
          pb: {
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
          },
        }}
      >
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          {/*<Grid*/}
            {/*item*/}
            {/*xs={9}*/}
            {/*md={6}*/}
            {/*lg={5}*/}
            {/*className={classes.flexCenter}*/}
          {/*>*/}
            {/*<img alt='' width="90%" src={require('../images/about/logoBack.png').default} />*/}
          {/*</Grid>*/}
          {/*<Grid*/}
            {/*item*/}
            {/*xs={7}*/}
            {/*md={5}*/}
            {/*lg={3}*/}
            {/*className={classes.flexCenter}*/}
          {/*>*/}
            {/*<img alt='' width="90%" src={require('../images/common/bxg-old.png').default} />*/}
          {/*</Grid>*/}
          <Grid
            item
            xs={10}
          >
            <SubTitleWhiteBox
              variant="subtitle1"
              gutterBottom
              align="center"
            >
              以人各行其爱而为人所爱<br />
              于己者，可有尺寸之地聚吾同道之人，可于方寸之间行吾所长，可于有生之年为吾所爱；<br />
              于世者，可展吾之至爱者于他人，可传华夏之文化于寰宇。<br />
            </SubTitleWhiteBox>
            <SubTitleEnBox
              variant="subtitle1"
              gutterBottom
              align="center"
            >
              We hope that we can be a place where you can pursue your
              love with more atmosphere. We hope that we can gather
              more people who agree with us and are agreed by us.
              We hope that in our limited life, we can do something
              that makes us feel happy and love. I hope that people
              who use our products can feel the love we pour into them.
              I hope that the beauty of traditional Chinese culture can
              be better preserved and passed on, and spread to the world.
            </SubTitleEnBox>
          </Grid>
        </Grid>
      </Container>
      {/*box2*/}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
          },
          pb: {
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
          },
        }}
      >
        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row-reverse',
            },
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <img alt="" width="100%" src={require('../images/about/02.jpg').default} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box sx={{overflow: 'hidden'}}>
              <Stack
                direction={{
                  xs: 'column',
                  md: 'row'
                }}
                spacing={0}
                justifyContent={{
                  xs: "center",
                  md: "flex-start"
                }}
                alignItems="center"
                className={classes.mb25}
              >
                <Box4Title
                  variant="h4"
                >
                  关于我们
                </Box4Title>
                <Box4Title
                  variant="h4"
                >
                  About Us
                </Box4Title>

              </Stack>
              <SubTitleWhiteBox
                variant="subtitle1"
                gutterBottom
              >
                深圳市赑玄阁科技有限公司成立于2015年, 由中科院深圳先进技术研究院、深圳市气象局、
                深圳市科创委联合孵化，是专业从事城市防灾减灾大数据分析、数据可视化、数据挖掘的方案型创新型企业。
              </SubTitleWhiteBox>
              <SubTitleEnBox
                variant="subtitle1"
                gutterBottom
              >
                The Big Pavilion was established in 2015, jointly incubated by Shenzhen Institute of Advanced Technology of Chinese Academy
                of Sciences, Shenzhen Meteorological Bureau and Shenzhen Science and
                Technology Innovation Commission, and is an innovative enterprise specializing
                in big data analysis, data visualization and data mining for urban disaster
                prevention and mitigation.
              </SubTitleEnBox>
              <Box className={classes.flexCenter} sx={{pt: 4}}>
                <img alt="" width='60%' src={require('../images/about/shangbiao.png').default} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* box3 */}
      {/*<Container*/}
        {/*maxWidth="xl"*/}
        {/*sx={{*/}
          {/*pt: {*/}
            {/*xs: 4,*/}
            {/*sm: 8,*/}
            {/*md: 16,*/}
            {/*lg: 24,*/}
          {/*},*/}
          {/*pb: {*/}
            {/*xs: 4,*/}
            {/*sm: 8,*/}
            {/*md: 16,*/}
            {/*lg: 24,*/}
          {/*},*/}
        {/*}}*/}
      {/*>*/}
        {/*<Stack*/}
          {/*direction={{*/}
            {/*xs: 'column',*/}
          {/*}}*/}
          {/*spacing={0}*/}
          {/*justifyContent={{*/}
            {/*xs: "center",*/}
          {/*}}*/}
          {/*alignItems="center"*/}
          {/*className={classes.mb25}*/}
        {/*>*/}
          {/*<Box4Title*/}
            {/*variant="h4"*/}
          {/*>*/}
            {/*我的资质*/}
          {/*</Box4Title>*/}
          {/*<Box4Title*/}
            {/*variant="h4"*/}
          {/*>*/}
            {/*Our Qualification*/}
          {/*</Box4Title>*/}
        {/*</Stack>*/}
        {/*<Grid*/}
          {/*container*/}
          {/*spacing={3}*/}
          {/*alignItems="center"*/}
        {/*>*/}
          {/*<Grid*/}
            {/*item*/}
            {/*xs={12}*/}
            {/*md={6}*/}
          {/*>*/}
            {/*<img alt="" width="100%" src={require('../images/about/03.png').default} />*/}
          {/*</Grid>*/}
          {/*<Grid*/}
            {/*item*/}
            {/*xs={12}*/}
            {/*md={6}*/}
          {/*>*/}
            {/*<Box sx={{overflow: 'hidden'}}>*/}
              {/*<Typography*/}
                {/*variant="subtitle1"*/}
                {/*gutterBottom*/}
                {/*sx={{*/}
                  {/*fontSize: {xs: '12px', sm: '14px', md: '1.2vw', lg: '16px',}*/}
                {/*}}>*/}
                {/*我们多次获得深圳市高新技术企业认证，拥有超过15项商标信息，*/}
                {/*9项专利信息，30项著作权，项资质认证以及超多35项知识产权认证证书。我们的产品也都拥有ISO9001质量管理及CE证书。*/}
              {/*</Typography>*/}
              {/*<Typography*/}
                {/*variant="subtitle1"*/}
                {/*gutterBottom*/}
                {/*sx={{*/}
                  {/*fontSize: {xs: '12px', sm: '14px', md: '1.2vw', lg: '16px',}*/}
                {/*}}*/}
              {/*>*/}
                {/*We have obtained Shenzhen high-tech enterprise certification many times, and have more than 15 trademark information,*/}
                {/*9 patent information, 30 copyrights, 1 qualification certification,*/}
                {/*and more than 35 intellectual property certifications. Our products also have ISO9001 quality management and CE certificates.*/}
              {/*</Typography>*/}
            {/*</Box>*/}
          {/*</Grid>*/}
        {/*</Grid>*/}
        {/*<Box className={classes.flexCenter} sx={{pt: 4}}>*/}
          {/*<img alt="" width='100%' src={require('../images/about/04.png').default} />*/}
        {/*</Box>*/}
      {/*</Container>*/}
      {/* box4 */}
      {/*<Box4*/}
        {/*maxWidth="xl"*/}
        {/*sx={{*/}
          {/*pt: {*/}
            {/*xs: 2,*/}
            {/*sm: 4,*/}
            {/*md: 8,*/}
            {/*lg: 16,*/}
          {/*},*/}
          {/*pb: {*/}
            {/*xs: 2,*/}
            {/*sm: 4,*/}
            {/*md: 8,*/}
            {/*lg: 16,*/}
          {/*}*/}
        {/*}}*/}
      {/*>*/}
        {/*<Grid*/}
          {/*container*/}
          {/*spacing={3}*/}
          {/*justifyContent="flex-end"*/}
          {/*alignItems="center"*/}
        {/*>*/}
          {/*<Grid*/}
            {/*item*/}
            {/*xs={12}*/}
            {/*// md={8}*/}
          {/*>*/}
            {/*<Box className={classes.box5Content} sx={{overflow: 'hidden', marginBottom: 5, p: 2}}>*/}
              {/*<Typography*/}
                {/*variant="subtitle1"*/}
                {/*gutterBottom*/}
                {/*sx={{*/}
                  {/*color: '#1BAFBF',*/}
                  {/*fontSize: {xs: '14px', sm: '16px', md: '1.6vw', lg: '24px',}*/}
                {/*}}*/}
                {/*align="right"*/}
              {/*>*/}
                {/*发表论文SCI 4篇*/}
                {/*<br />*/}
                {/*Published 4 SCI papers*/}
              {/*</Typography>*/}
            {/*</Box>*/}
          {/*</Grid>*/}
        {/*</Grid>*/}
      {/*</Box4>*/}
      {/* box5 */}
      {/*<Box sx={{*/}
        {/*background: '#0D3140',*/}
        {/*p: {*/}
          {/*xs: 2,*/}
          {/*sm: 3,*/}
          {/*md: 4,*/}
          {/*lg: 5,*/}
        {/*}*/}
      {/*}}>*/}
        {/*<Container*/}
          {/*maxWidth="xl"*/}
          {/*className={classes.box5Content}*/}
        {/*>*/}
          {/*<Typography*/}
            {/*variant="subtitle1"*/}
            {/*gutterBottom*/}
            {/*sx={{*/}
              {/*color: '#1BAFBF',*/}
              {/*fontSize: {xs: '14px', sm: '16px', md: '1.6vw', lg: '24px',}*/}
            {/*}}>*/}
            {/*发表论文SCI 4篇*/}
            {/*<br />*/}
            {/*Published 4 SCI papers*/}
          {/*</Typography>*/}
        {/*</Container>*/}
      {/*</Box>*/}
      {/* box6 */}
      {/*<Container*/}
        {/*maxWidth="xl"*/}
        {/*sx={{*/}
          {/*pt: {*/}
            {/*xs: 4,*/}
            {/*sm: 8,*/}
            {/*md: 16,*/}
            {/*lg: 24,*/}
          {/*},*/}
          {/*pb: {*/}
            {/*xs: 4,*/}
            {/*sm: 8,*/}
            {/*md: 16,*/}
            {/*lg: 24,*/}
          {/*},*/}
        {/*}}*/}
      {/*>*/}
        {/*<Stack*/}
          {/*direction={{*/}
            {/*xs: 'column',*/}
          {/*}}*/}
          {/*spacing={0}*/}
          {/*justifyContent={{*/}
            {/*xs: "center",*/}
          {/*}}*/}
          {/*sx={{*/}
            {/*pb: {*/}
              {/*xs: 2,*/}
              {/*sm: 4,*/}
              {/*md: 8,*/}
              {/*lg: 12,*/}
            {/*}*/}
          {/*}}*/}
          {/*alignItems="center"*/}
          {/*className={classes.mb25}*/}
        {/*>*/}
          {/*<Box4Title*/}
            {/*variant="h4"*/}
          {/*>*/}
            {/*部分荣誉*/}
          {/*</Box4Title>*/}
          {/*<Box4Title*/}
            {/*variant="h4"*/}
          {/*>*/}
            {/*Some of the honors*/}
          {/*</Box4Title>*/}
        {/*</Stack>*/}
        {/*<Grid*/}
          {/*container*/}
          {/*spacing={3}*/}
          {/*alignItems="stretch"*/}
        {/*>*/}
          {/*<Grid*/}
            {/*item*/}
            {/*xs={12}*/}
            {/*md={6}*/}
          {/*>*/}
            {/*<img alt="" width="100%" src={require('../images/about/08.jpg').default}/>*/}
            {/*<Typography*/}
              {/*variant="subtitle1"*/}
              {/*gutterBottom*/}
              {/*sx={{*/}
                {/*fontSize: {xs: '12px', sm: '1.2vw', lg: '14px',}*/}
              {/*}}>*/}
              {/*2015年首届双创，从全国3000个团队中脱颖而出，向李克强总理介绍产品和企业。*/}
              {/*<br />*/}
              {/*<span className={classes.honor1}>*/}
                {/*In 2015, the first double creation was selected from 3,000 teams nationwide to introduce their products and companies to Premier Li Keqiang.*/}
              {/*</span>*/}
            {/*</Typography>*/}
          {/*</Grid>*/}
          {/*<Grid*/}
            {/*item*/}
            {/*xs={12}*/}
            {/*md={6}*/}
          {/*>*/}
            {/*<img alt="" width="100%" src={require('../images/about/09.jpg').default}/>*/}
            {/*<Typography*/}
              {/*variant="subtitle1"*/}
              {/*gutterBottom*/}
              {/*sx={{*/}
                {/*fontSize: {xs: '12px', sm: '1.2vw', lg: '14px',}*/}
              {/*}}>*/}
              {/*2016年双创，再次向李克强总理介绍项目，总理还记得我们团队，并即兴发表演讲鼓励。*/}
              {/*<br />*/}
              {/*<span className={classes.honor1}>*/}
                {/*In 2016, we introduced the project to Premier Li Keqiang again, who remembered our team and gave an impromptu speech of encouragement.*/}
              {/*</span>*/}
            {/*</Typography>*/}
          {/*</Grid>*/}
          {/*<Grid*/}
            {/*item*/}
            {/*xs={12}*/}
            {/*md={8}*/}
          {/*>*/}
            {/*<img alt="" width="100%" src={require('../images/about/20.jpg').default}/>*/}
          {/*</Grid>*/}
          {/*<Grid*/}
            {/*item*/}
            {/*xs={12}*/}
            {/*md={4}*/}
            {/*sx={{*/}
              {/*flexDirection: {*/}
                {/*xs: 'row',*/}
                {/*md: 'column'*/}
              {/*},*/}
              {/*display: 'flex',*/}
              {/*justifyContent: 'space-between'*/}
            {/*}}*/}
          {/*>*/}
            {/*<Box*/}
              {/*sx={{*/}
                {/*width: {*/}
                  {/*xs: '47%',*/}
                  {/*md: '100%',*/}
                {/*},*/}
              {/*}}*/}
            {/*>*/}
              {/*<img  alt="" width='100%' src={require('../images/about/21.jpg').default} />*/}
            {/*</Box>*/}
            {/*<Box*/}
              {/*sx={{*/}
                {/*width: {*/}
                  {/*xs: '47%',*/}
                  {/*md: '100%',*/}
                {/*},*/}
              {/*}}*/}
            {/*>*/}
              {/*<img alt="" width='100%' src={require('../images/about/22.jpg').default} />*/}
            {/*</Box>*/}
          {/*</Grid>*/}
          {/*<Grid*/}
            {/*item*/}
            {/*xs={12}*/}
            {/*md={8}*/}
          {/*>*/}
            {/*<Typography*/}
              {/*variant="subtitle1"*/}
              {/*gutterBottom*/}
              {/*sx={{*/}
                {/*fontSize: {xs: '12px', sm: '1.2vw', lg: '14px',}*/}
              {/*}}>*/}
              {/*团队代表深圳参加粤港澳大湾区人工智能比赛荣获一等奖*/}
              {/*<br />*/}
              {/*<span className={classes.honor1}>*/}
                {/*The team won the first prize in the Guangdong-Hong Kong-Macau-Gulf Region Artificial Intelligence Competition on behalf of Shenzhen*/}
              {/*</span>*/}
            {/*</Typography>*/}
          {/*</Grid>*/}
        {/*</Grid>*/}
      {/*</Container>*/}
      {/* box end */}
    </Layout>
  )
}

export default About
