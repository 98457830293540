export const seoTitle = '关于我们 | 赑玄阁';
export const seoDescription = '深圳市赑玄阁科技有限公司成立于2015年, 由中科院深圳先进技术研究院、深圳市气象局、 深圳市科创委联合孵化，是专业从事城市防灾减灾大数据分析、数据可视化、数据挖掘的方案型创新型企业。';
export const browserTitle = '关于我们 | 赑玄阁';
export const keywords = '赑玄阁, 赑玄阁介绍, 气象信息, 中科院, 深圳气象局, 防灾减灾, 数据分析';


export const textData = [
  [
    '〇 巨灵赑屃 首冠灵山',
    '高掌远跖 以流河曲',
    '厥迹犹存 作力之貌',
    '古有龟蛇 名曰霸下',
    '霸下之大 不知其几千里',
    '且夫壳之坚也不足 则其负大身也无力',
    '性好静 不为浮华所扰 不为荣名所动',
    '碑趺象之 含蓄内敛',
    '至劲者强 至宝者贵',
  ],
  [
    '〇 玄 大也 幽远也',
    '黑而有赤色者为玄',
    '象幽而入复之也',
    '古有神兽玄武',
    '其远志非圣贤难解',
    '其心恒如泰山不移',
    '不以物之兴衰而改',
    '不随人之喜憎而迁',
    '虚无飘渺谓之幽',
    '宽大悠远名曰玄',
    '其智谋邃如玄湖',
    '其心胸广若深谷',
    '古云天圆地方',
    '愿取天地之灵息',
    '铸以此'
  ],
  [
    '〇 阁者 安定之所也',
    '亭者 停集之地也'
  ]
];
